<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#13736f"
      spinner="spinner"
    />
    <v-layout class="mainfont" pb-10 wrap justify-center>
      <v-flex xs10 pt-4 pb-6>
        <v-card class="pr-10 pt-10 pl-10">
          <v-layout wrap pt-6 justify-center>
            <v-flex pt-4 xs11 v-if="list.categoryId && list.categoryId.name">
              <v-text-field
                class="textField2"
                dense
                disabled
                v-model="list.categoryId.name"
                outlined
                label="Category"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs11>
              <v-combobox
                outlined
                dense
                color="#8d8d8d"
                :items="lottypelist"
                v-model="lottypesitem"
                item-text="name"
                item-color="#FF1313"
                hide-details
                clearable
                label="Lot Type"
                :search-input.sync="userEnteredText"
                allow-overflow
              >
              </v-combobox>
            </v-flex>
            <v-flex xs11 pt-4>
              <v-autocomplete
                outlined
                dense
                color="#8d8d8d"
                :items="harvest"
                v-model="list.harvest"
                item-text="name"
                item-color="#FF1313"
                hide-details
                clearable
                label="Harvest"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs11 pt-4>
              <v-layout wrap>
                <v-flex xs5 pa-3 v-for="(item, i) in list.photos" :key="i">
                  <v-img :src="mediaURL + item"></v-img>
                </v-flex>

                <v-flex xs6>
                  <Images
                    @stepper="winStepper"
                    :height="'800'"
                    :width="'600'"
                    :heading="'Upload Images'"
                  />
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex pt-4 xs11>
              <v-text-field
                class="textField2"
                dense
                v-model="list.expectedPrice"
                outlined
                label="Expected  Price (Rs)"
                required
              ></v-text-field>
            </v-flex>
            <v-flex pt-4 xs11>
              <v-text-field
                class="textField2"
                dense
                v-model="list.startingPrice"
                outlined
                label="Starting  Price (Rs)"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs11>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    dense
                    outlined
                    label=" Date"
                    readonly
                    v-model="cdate"
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedDate"
                    @click:clear="from = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="cdate"
                  color="#13736f"
                  @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6 pl-13>
              <template>
                <v-row>
                  <v-col cols="11" sm="5">
                    <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="startingTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startingTime"
                          outlined
                          dense
                          label="Starting Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        v-model="startingTime"
                        full-width
                        @click:minute="$refs.menu.save(startingTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </template>
            </v-flex>

            <v-flex xs6>
              <template>
                <v-row>
                  <v-col cols="11" sm="5">
                    <v-menu
                      ref="menu2"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="menu2"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endingTime"
                          label="Ending Time"
                          dense
                          outlined
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu3"
                        v-model="endingTime"
                        full-width
                        @change="$refs.menu2.save(endingTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </template>
            </v-flex>
            <v-flex xs11>
              <v-autocomplete
                outlined
                dense
                color="#8d8d8d"
                :items="bagtypelist"
                v-model="list.bagType"
                item-text="name"
                item-color="#FF1313"
                hide-details
                clearable
                label="Bag Type"
                allow-overflow
              >
              </v-autocomplete>
            </v-flex>

            <v-flex xs11 pt-4>
              <v-text-field
                class="textField2"
                dense
                v-model="list.bagCount"
                outlined
                hide-details="true"
                label="Bag Count"
                required
              ></v-text-field>
            </v-flex>

            <!-- <v-flex xs11>
              <v-text-field
                class="textField2"
                dense
                v-model="list.bagPrice"
                outlined
                label="Bag Price(Rs)"
                required
              ></v-text-field>
            </v-flex> -->
            <v-flex xs11 pt-4 v-if="categoryId === '6464813503997785068a37b8'">
              <span>Grades</span>
              <v-card elevation="0" v-for="(item, i) in grades" :key="i">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs12 pt-3>
                        <v-autocomplete
                          outlined
                          dense
                          hide-details="true"
                          color="#8d8d8d"
                          :items="gradetypelist"
                          v-model="item.grade"
                          item-text="name"
                          item-color="#FF1313"
                          clearable
                          label="Change Grade"
                        >
                        </v-autocomplete>
                      </v-flex>
                      <v-flex xs12 pt-6>
                        <v-text-field
                          class="textField2"
                          dense
                          v-model="item.clean"
                          outlined
                          label="Clean (in %)"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          class="textField2"
                          dense
                          v-model="item.sickSplit"
                          outlined
                          label="Sick & Split (in %)"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          class="textField2"
                          dense
                          v-model="item.fruitWhitish"
                          outlined
                          label="Fruit & Whitish (in %)"
                          required
                        ></v-text-field
                      ></v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex
              xs11
              pt-3
              v-if="
                categoryId === '6464813503997785068a37b8' ||
                categoryId === '6482af8b27d90e40a40a2ff2'
              "
            >
              <v-text-field
                class="textField2"
                dense
                v-model="list.literWeight"
                outlined
                label="Liter Weight (gm)"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs11>
              <v-text-field
                class="textField2"
                dense
                v-model="list.moistureContent"
                outlined
                label="Moisture Content (%)"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs11 v-if="categoryId === '6464813503997785068a37b8'">
              <v-combobox
                outlined
                dense
                color="#8d8d8d"
                :items="colorlist"
                v-model="majorityitem"
                item-text="name"
                item-color="#FF1313"
                hide-details
                clearable
                label="Majority Color"
                :search-input.sync="userEnteredText2"
                allow-overflow
              >
              </v-combobox>
            </v-flex>

            <v-flex xs11 pt-4>
              <v-text-field
                class="textField2"
                dense
                v-model="list.netWeight"
                outlined
                label="Net Weight ( Kg) "
                required
              ></v-text-field>
            </v-flex>

            <v-flex
              xs11
              v-if="
                categoryId === '648d58b691a663942af701ae' ||
                categoryId === '6482af8b27d90e40a40a2ff2'
              "
            >
              <v-text-field
                class="textField2"
                dense
                v-model="list.dust"
                outlined
                label="Dust (%)"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs11 v-if="categoryId === '648d58b691a663942af701ae'">
              <v-text-field
                class="textField2"
                dense
                v-model="list.buds"
                outlined
                type="number"
                label="Buds (%)"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs11 v-if="categoryId === '6482af8b27d90e40a40a2ff2'">
              <v-text-field
                class="textField2"
                dense
                v-model="list.white"
                outlined
                label="White Pepper (%)"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs11 v-if="categoryId === '6482af8b27d90e40a40a2ff2'">
              <v-autocomplete
                outlined
                dense
                color="#8d8d8d"
                :items="itemArray2"
                v-model="list.size"
                item-text="name"
                item-color="#FF1313"
                hide-details
                clearable
                label=" Size"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs11 v-if="categoryId === '6482af8b27d90e40a40a2ff2'">
              <v-checkbox
                outlined
                dense
                v-model="list.isFungus"
                label=" Fungus Affected"
              ></v-checkbox>
            </v-flex>

            <v-flex xs11>
              <v-text-field
                class="textField2"
                dense
                v-model="list.description"
                outlined
                label="Description"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs11>
              <v-text-field
                class="textField2"
                dense
                v-model="list.creditDays"
                outlined
                type="number"
                label="Credit Days"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs11>
              <v-text-field
                class="textField2"
                dense
                type="number"
                v-model="list.discount"
                outlined
                label="Discount (%)"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs11 v-if="list.status === 'Won'">
              <v-card
                class="pb-4 pl-4"
                style="background-color: #f2f2f2; font-size: 15px"
              >
                <v-layout wrap justify-start>
                  <v-flex xs4 pt-4>
                    <span>Lot Number: {{ list.lotNumber }}</span>
                  </v-flex>
                  <v-flex xs4 pt-4>
                    <span>Net Weight: {{ list.netWeight }} Kg</span>
                  </v-flex>
                  <v-flex xs4 pt-4>
                    <span v-if="list.bagType">Bag : {{ list.bagType }} </span>
                    <span v-else>Bag : Nil </span>
                  </v-flex>
                  <v-flex xs4 pt-4>
                    <span v-if="list.bagCount"
                      >Bag Count : {{ list.bagCount }}
                    </span>
                    <span v-else> Bag Count : 0</span>
                  </v-flex>

                  <v-flex xs4 pt-4>
                    <span> Price per Kg (Bid Price) :{{ list.bidPrice }} </span>
                  </v-flex>
                  <v-flex xs4 pt-4>
                    <span v-if="list.itemAmount">
                      Value :{{ list.itemAmount }}
                    </span>
                    <span v-else> Value :0 </span>
                  </v-flex>
                  <v-flex xs4 pt-4>
                    <span> Discount {{ list.discount }} %</span>:
                    <span v-if="list.discountAmount">{{
                      list.discountAmount
                    }}</span>
                    <span v-else>0</span>
                  </v-flex>
                  <v-flex xs3 pt-4>
                    <span v-if="list.totalBagPrice"
                      >Bag Cost:{{ list.totalBagPrice }}</span
                    >
                    <span v-else>Bag Cost: 0</span>
                  </v-flex>

                  <v-flex pl-6 xs5 pt-4 text-center>
                    <span v-if="list.taxPercentage"
                      >Net Amount Include GST ({{ list.taxPercentage }} %) :
                      {{ list.totalAmount }}
                    </span>
                    <span v-else> Net Amount Include GST (0 %): 0 </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>

            <v-flex xs11 text-right pb-10>
              <v-layout pl-5 wrap justify-end>
                <v-flex xs3 v-if="list.status === 'Pending'" pa-3>
                  <v-btn block color="#13736f" dark @click="approve = true">
                    <span
                      class="mainfont"
                      style="color: white; font-size: 14px"
                    >
                      Approve
                    </span>
                    <v-icon size="110%" color="white">mdi-check-all</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs3 v-if="list.status === 'Pending'" pl-3 pb-3 pt-3>
                  <v-btn block color="#13736f" dark @click="reject = true">
                    <span
                      class="mainfont"
                      style="color: red; font-size: 14px; font-weight: bold"
                      >Reject
                    </span>
                    <v-icon color="red">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
                <!-- <v-flex xs4 v-else pl-6>
                <v-btn
                width="160px" color="#13736f"
                  block
                  
                  dark
                  @click="edit()"
                >
                  <span class="mainfont" style="color: white; font-size: 14px;"> Edit </span>
                  <v-icon size="110%" color="white">mdi-pencil</v-icon>
                </v-btn>
              </v-flex> -->
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="approve"
      max-width="600px"
    >
      <v-card>
        <v-layout wrap>
          <v-flex xs12>
            <v-card-title
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: lighter"
            >
              Are you sure you want to Approve?
            </v-card-title>
          </v-flex>
        </v-layout>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="approve = false" class="mainfont"
            >Cancel</v-btn
          >
          <v-btn
            color="red"
            class="mainfont"
            text
            @click="userAction2('Approved')"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="reject"
      max-width="600px"
    >
      <v-card>
        <v-layout wrap justify-center>
          <v-flex xs8>
            <v-card-title
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: lighter"
            >
              Are you sure you want to Reject?
            </v-card-title>
            <v-flex xs10>
              <v-text-field
                class="mainfont"
                dense
                v-model="remarks"
                outlined
                label="Remarks"
                required
              ></v-text-field>
            </v-flex>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="reject = false" class="mainfont"
            >Cancel</v-btn
          >
          <v-btn
            color="red"
            class="mainfont"
            text
            @click="userAction('Rejected')"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
      
<script>
import axios from "axios";
import Images from "@/components/Common/multipleImages";
import moment from "moment";
export default {
  components: {
    Images,
  },
  data() {
    return {
      approve: false,
      remarks: null,
      preview: null,
      // itemArray: ["8mm", "7mm", "6mm"],
      itemArray2: ["120", "80", "40"],
      // color: ["Normal Green", "Reddish", "Light Yellow"],
      colorlist: [],
      lottype: ["Bulk", "Average", "Few"],
      harvest: ["New", "Old"],
      isFungus: false,
      link: null,
      page: 1,
      reject: null,
      categoryId: null,
      startingTime: "",
      endingTime: "",
      time: null,
      menu2: false,
      menu3: false,
      modal2: false,
      currentpage: 1,
      showSnackBar: false,
      msg: null,
      limit: 10,
      url: null,
      categoryName: null,
      id: this.$route.query.id,
      imageFiles: [],
      appLoading: false,
      name: null,
      description: null,
      duration: null,
      pages: 0,
      menu1: false,
      userEnteredText: "",
      userEnteredText2: "",

      icon: null,
      lottypesitem: null,
      formData: new FormData(),
      formData2: new FormData(),
      listingOrder: null,
      list: [],
      curid: [],
      gradetypesitem: "",
      grades: [],
      cdate: {},
      startingPrice: null,
      deletedialog: false,
      time2: null,
      majorityitem: "",
      editdialog: false,
      noOfDays: null,
      amount: null,
      lottypelist: [],
      bagtypelist: [],
      color: "",
      bagTypeitem: "",
      gradetypelist: [],
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
    this.getLottype();
    this.getgradetype();
    this.bagtype();
    this.majorityColors();
  },

  // watch: {
  //   lottypesitem() {
  //     this.getLottype();
  //   },
  // },
  computed: {
    formattedDate() {
      const date = new Date(this.cdate);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
  created() {
    this.currentPage2 = parseInt(this.$route.query.currentPage2) || 1;
  },

  methods: {
    updateEnteredText2() {
      this.userEnteredText2 = this.majorityitem;
    },
    updateEnteredText() {
      this.userEnteredText = this.lottypesitem;
    },
    majorityColors() {
      this.appLoading = true;
      axios
        .get("/lot/majorityColor/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.colorlist = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    bagtype() {
      this.appLoading = true;
      axios
        .get("/bagType/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            searchKeyword: this.keysearch,
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.bagtypelist = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getgradetype() {
      this.appLoading = true;
      axios
        .get("/lot/grade/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            searchKeyword: this.keysearch,
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.gradetypelist = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getLottype() {
      this.appLoading = true;
      axios
        .get("/lot/lotType/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            searchKeyword: this.keysearch,
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.lottypelist = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      console.log("imgfiles=", this.imageFiles);
      this.formData2.append("lotType", this.list.lotType);
      this.formData2.append("harvest", this.list.harvest);
      this.formData2.append("expectedPrice", this.list.expectedPrice);
      this.formData2.append("startingPrice", this.list.startingPrice);
      this.formData2.append("date", this.cdate);
      const startingDateTime = moment(
        `${this.cdate} ${this.startingTime}`,
        "YYYY-MM-DD hh:mm A"
      ).format("YYYY-MM-DD HH:mm:ss");
      this.formData2.append("startingTime", startingDateTime);
      const endingDateTime = moment(
        `${this.cdate} ${this.endingTime}`,
        "YYYY-MM-DD hh:mm A"
      ).format("YYYY-MM-DD HH:mm:ss");
      this.formData2.append("endingTime", endingDateTime);
      this.formData2.append("bagCount", this.list.bagCount);
      this.formData2.append("bagPrice", this.list.bagPrice);
      this.formData2.append("literWeight", this.list.literWeight);
      this.formData2.append("moistureContent", this.list.moistureContent);
      this.formData2.append("majorityColor", this.list.majorityColor);
      this.formData2.append("netWeight", this.list.netWeight);
      this.formData2.append("dust", this.list.dust);
      this.formData2.append("white", this.list.white);
      this.formData2.append("size", this.list.size);
      this.formData2.append("isFungus", this.list.isFungus);
      const editedGrades = [];
      this.grades.forEach((item) => {
        const editedGrade = {
          grade: item.grade,
          clean: item.clean,
          fruitWhitish: item.fruitWhitish,
          sickSplit: item.sickSplit,
        };
        editedGrades.push(editedGrade);
      });
      this.formData2.append("grades", JSON.stringify(editedGrades));
      for (var i = 0; i < this.imageFiles.length; i++) {
        this.formData2.append(
          "photos",
          this.imageFiles[i],
          this.imageFiles[i].name
        );
      }
      axios({
        method: "post",
        url: "/admin/lot/edit",
        data: this.formData2,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    userAction2(status) {
      this.appLoading = true;
      console.log("imgfiles=", this.imageFiles);
      this.formData2.append("bagType", this.list.bagType);

      this.formData2.append("discount", this.list.discount);
      this.formData2.append("creditDays", this.list.creditDays);
      this.formData2.append("description", this.list.description);

      this.formData2.append("lotType", this.userEnteredText);
      this.formData2.append("majorityColor", this.userEnteredText2);

      this.formData2.append("harvest", this.list.harvest);
      this.formData2.append("expectedPrice", this.list.expectedPrice);
      this.formData2.append("startingPrice", this.list.startingPrice);
      this.formData2.append("date", this.cdate);
      const startingDateTime = moment(
        `${this.cdate} ${this.startingTime}`,
        "YYYY-MM-DD hh:mm A"
      ).format("YYYY-MM-DD HH:mm:ss");
      this.formData2.append("startingTime", startingDateTime);
      const endingDateTime = moment(
        `${this.cdate} ${this.endingTime}`,
        "YYYY-MM-DD hh:mm A"
      ).format("YYYY-MM-DD HH:mm:ss");
      this.formData2.append("endingTime", endingDateTime);
      this.formData2.append("bagCount", this.list.bagCount);
      this.formData2.append("bagPrice", this.list.bagPrice);
      this.formData2.append("literWeight", this.list.literWeight);
      this.formData2.append("moistureContent", this.list.moistureContent);
      // this.formData2.append("majorityColor", this.list.majorityColor);
      this.formData2.append("netWeight", this.list.netWeight);
      this.formData2.append("dust", this.list.dust);
      this.formData2.append("white", this.list.white);
      this.formData2.append("size", this.list.size);
      this.formData2.append("isFungus", this.list.isFungus);
      this.formData2.append("status", status);
      this.formData2.append("remarks", this.list.remarks);
      this.formData2.append("id", this.list._id);
      const editedGrades = [];
      this.grades.forEach((item) => {
        const editedGrade = {
          grade: item.grade,
          clean: item.clean,
          fruitWhitish: item.fruitWhitish,
          sickSplit: item.sickSplit,
        };
        editedGrades.push(editedGrade);
      });
      this.formData2.append("grades", JSON.stringify(editedGrades));
      for (var i = 0; i < this.imageFiles.length; i++) {
        this.formData2.append(
          "photos",
          this.imageFiles[i],
          this.imageFiles[i].name
        );
      }
      axios({
        method: "POST",
        url: "/admin/lot/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.formData2,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$router.push("/LotsPage");
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    winStepper(window_data) {
      if (window_data.type == "AddPackage") {
        this.slotAddition = window_data.slotAddition;
        this.packageRate = window_data.response;
      } else if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "topimage") {
        this.topimageFile = window_data.selectedFiles;
      } else if (window_data.type == "product top image") {
        this.producttopimagefile = window_data.selectedFiles;
      } else if (window_data.type == "product right image") {
        this.productrightimagefile = window_data.selectedFiles;
      }
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "lot/view/" + this.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.lottypesitem = this.list.lotType;
          this.gradetypesitem = this.list.grade;
          this.bagTypeitem = this.list.bagType;
          this.majorityitem = this.list.majorityColor;
          this.grades = this.list.grades;
          this.cdate = this.list.date;
          var startingTime = new Date(this.list.startingTime);
          this.startingTime = startingTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hourCycle: "h23",
          });
          var endingTime = new Date(this.list.endingTime);
          this.endingTime = endingTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hourCycle: "h23",
          });
          this.cdate = moment(this.cdate).format("YYYY-MM-DD");
          this.categoryId = this.list.categoryId._id;
          console.log("id is ", this.categoryId);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
    
    
<style scoped>
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
</style>